<template>
  <modal @update:show="onShowChange" :show="show">
    <h6 slot="header" class="modal-title">{{ ride.id ? this.$t('message.editRide') : this.$t('message.newRide') }}</h6>

    <div v-loading="isLoading">
      <div class="mt-2 mb-3" v-if="ride.id">
        <b-badge variant="primary" v-if="parent && parent.address" @click="onRelatedClicked(parent.id)" class="clickable mr-2 ">{{parent.address.name}}</b-badge>
        <b-badge variant="secondary" v-for="child in childs" :key="child.id" class="mr-2 clickable" @click="onRelatedClicked(child.id)">{{child.address.name}}</b-badge>
        <b-badge variant="secondary" v-for="sibling in siblings" :key="sibling.id" class="mr-2 clickable" @click="onRelatedClicked(sibling.id)">{{sibling.address.name}}</b-badge>
      </div>
      <base-input :label="$t('message.ride')"  v-if="!!parentRide" v-model="parentRide" :disabled="true">
      </base-input>
      <base-input :label="$t('message.einsatzort')" :validator="$v.ride.address_id">
        <el-select v-model="ride.address_id" filterable :placeholder="$t('message.chooseEinsatzort')">
          <el-option
            v-for="address in projectAddresses"
            :key="address.address.id"
            :label="`${address.address.name}, ${address.address.zip} ${address.address.city}`"
            :value="address.address.id"
          ></el-option>
        </el-select>
      </base-input>

      <base-input :label="$t('message.chooseVehicleFleet')" :validator="$v.ride.vehicle_id">
        <el-select v-model="ride.vehicle_id" filterable :placeholder="$t('message.chooseVehicle')" :disabled="!!parentRide">
          <el-option
            v-for="vehicle in vehicles"
            :key="vehicle.id"
            :label="`${vehicle.type} ${vehicle.license}`"
            :value="vehicle.id"
          ></el-option>
        </el-select>
      </base-input>

      <div class="border border-primary p-3 mb-4" v-if="ride.vehicle_id === 32">
        <p>Jede <strong>Nachfahrt</strong> muss schriftlich vom Kunden freigegeben werden, wenn die Absicht besteht diese weiterzuberechnen!</p>
        <base-input label="" class="mb--2" :validator="$v.ride.followUp">
          <el-checkbox @change="getVehicle">Verstanden</el-checkbox>
        </base-input>
      </div>

      <base-input required :label="$t('message.driver')" :placeholder="$t('message.driver')" v-model="ride.driver" :validator="$v.ride.driver"></base-input>
      <base-input required :label="$t('message.notice')" :placeholder="$t('message.notice')" v-model="ride.notice" :validator="$v.ride.notice"></base-input>
      <base-input required :label="$t('message.licensePlate')" :placeholder="$t('message.licensePlate')" v-model="ride.license" :validator="$v.ride.license"></base-input>

      <date-picker :label="$t('message.loadingPlus')" v-model="ride.load"  :validator="$v.ride.load"/>
      <date-picker :label="$t('message.departurePlus')" v-model="ride.departure" :validator="$v.ride.departure"/>
      <date-picker :label="$t('message.unloadingOnSite')" v-model="ride.unload" :validator="$v.ride.unload"/>
      <date-picker :label="$t('message.startOfAssemby')" v-model="ride.assembly" :validator="$v.ride.assembly"/>
      <date-picker :label="$t('message.loadingOnSite')" v-model="ride.load_back" :validator="$v.ride.load_back"/>
      <date-picker :label="$t('message.unLoadingOnSite')" v-model="ride.unload_back" :validator="$v.ride.unload_back"/>
    </div>

    <template slot="footer">
      <base-button type="link" class="ml-auto" @click="onClose">{{$t('message.cancel')}}</base-button>
      <base-button type="primary" @click="onSave()" :disabled="$v.$invalid">{{$t('message.save')}}</base-button>
      <base-button type="primary" @click="onSave(true)" :disabled="$v.$invalid">{{$t('message.save_new_ride')}}</base-button>
    </template>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import DatePicker from "./DatePicker";
import { BBadge } from 'bootstrap-vue';
import Vue from "vue"

export default {
  name: "rides-form",
  components: {
    DatePicker,
    BBadge
  },
  props: {
    ride: {
      type: Object
    },
    show: {
      type: Boolean
    },
    parentRideId: {
      type: Number
    },
    rides: {
      type: Array
    },
    syncEnabled: {
      type: Boolean
    }
  },
  mounted() {
    //this.$store.dispatch("findVehicles");
    this.isLoading = false;
  },
  computed: {
    ...mapGetters(["vehicles", "projectAddresses"]),
    parentRide () {
      const parentRideId = this.ride.parentRideId || this.parentRideId
      const ride = this.rides.find(r => r.id === parentRideId)
      return ride ? `${ride.load} - ${ride.vehicle.license}` : false
    },
    parent () {
      if (this.ride.parentRideId) {
        return this.rides.find(r => r.id === this.ride.parentRideId)
      }
      return this.ride
    },
    childs () {
      return this.rides.filter(r => r.parentRideId === this.ride.id)
    },
    siblings () {
      if (this.ride.parentRideId) {
        return this.rides.filter(r => r.parentRideId === this.ride.parentRideId)
      } 
      return []
    }
  },
  data() {
    return {
      dateTimePicker: {
        enableTime: true,
        time_24hr: true,
        dateFormat: "Y-m-d H:i:00",
        altInput: true,
        altFormat: "d.m.Y H:i"
      },
      isLoading: true,
      understood: false
    };
  },
  watch: {
    'ride.load' (val, old) {
      if (val && !old && this.ride.id === undefined) {
        this.ride.departure = val
        this.ride.unload = val
        this.ride.assembly = val
        this.ride.load_back = val
        this.ride.unload_back = val
      }
    },
    parentRideId (val) {
      if (this.ride) {
        this.ride.parentRideId = val
      }
    },
    show() {
      this.understood = false;
    }
  },
  validations: {
    ride: {
      address_id: {required},
      vehicle_id: {required},
      followUp: {
        required: requiredIf(function() {
          return this.$v.ride.vehicle_id.$model == 32 && !this.understood
        })
      },
      load: {required},
      // departure: {required},
      // unload: {required},
      // assembly: {required},
      // load_back: {required},
      // unload_back: {required},
    },
  },
  methods: {
    getVehicle(val) {
      this.understood = val;
    },
    onClose() {
      this.onShowChange(false);
    },
    onSave: async function(createNew = false) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return
      }

      const { $store, ride } = this;
      this.isLoading = true;

      delete ride.vehicle;

      if (ride.id) {
        await $store.dispatch("updateRide", {
          id: ride.id,
          data: ride
        })
        .then(() => {
          if (this.syncEnabled && this.transferRide(ride)) {
            this.updatePT(ride.id);
          }
        });
      } else {
        const { id } = await $store.dispatch("createRide", { data: ride });
        ride.id = id;
        if (this.syncEnabled && this.transferRide(ride)) {
          this.updatePT(ride.id);
        }
      }

      this.isLoading = false;

      if (createNew === false) {
        this.$emit("changed");
      } else {
        const parentRideId = ride.parentRideId ? ride.parentRideId : ride.id
        this.$emit("create-new-with-parent", parentRideId);
      }
      this.onClose();
      
    },
    async updatePT(rideID) {
      try {
        await Vue.http['get']('pt/ridesync/' + rideID, {});
        this.$notify({
          verticalAlign: 'top',
          horizontalAlign: 'right',
          message: 'Daten wurden an PT übertragen.',
          type: 'success'
        })
      } catch(response) {
        this.$notify({
          verticalAlign: 'top',
          horizontalAlign: 'right',
          message: 'Fehler bei Übertragung an PT: <br>' + response.body.message,
          type: 'error'
        });
      }
    },
    transferRide(ride) {
      const vehicleType = this.vehicles.filter(v => v.id == ride.vehicle_id).map(v => v.type);

      switch (vehicleType[0].toLowerCase()) {
        case 'transferfahrt':
        case 'direkter versand':
          return false;
      }
      return true;
    },
    onShowChange(state) {
      this.$emit("update:show", state);
    },
    onRelatedClicked(id) {
      this.onClose()
      this.$emit("load-item", id);
    }
  }
};
</script>
