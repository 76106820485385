var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"d-flex"},[_c('h3',{staticClass:"m-0 pr-4 flex-grow-1"},[_vm._v(_vm._s(_vm.$t('message.rides')))]),_c('el-tooltip',{attrs:{"content":_vm.$t('message.new'),"placement":"top"}},[_c('base-button',{attrs:{"type":"primary","icon":"","round":"","size":"sm","disabled":_vm.project.state === 'ARCHIVED'},on:{"click":_vm.onCreate}},[_c('i',{staticClass:"fas fa-plus p-0"})])],1)],1)]),_c('el-table',{staticClass:"table-responsive table-compact",attrs:{"header-row-class-name":"thead-light","row-key":"id","data":_vm.parentRides}},[_c('el-table-column',{attrs:{"width":"40"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.hasChildren(row))?_c('i',{staticClass:"fas fa-code-branch"}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('message.ladeTermin'),"prop":"load","sortable":"","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("datetime")(row.load))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('message.einsatzort'),"prop":"address.name","sortable":""}}),_c('el-table-column',{attrs:{"label":_vm.$t('message.vehicle'),"prop":"vehicle.type","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.vehicle)?_c('div',[_vm._v(" "+_vm._s(row.notice ? row.vehicle.type +' / '+ row.notice : row.vehicle.type)+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"","width":"88"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{attrs:{"content":_vm.$t('message.edit'),"placement":"top"}},[_c('base-button',{attrs:{"type":"link","size":"sm","disabled":_vm.project.state === 'ARCHIVED'},on:{"click":function($event){return _vm.onEdit(row)}}},[_c('i',{staticClass:"fas fa-edit text-black"})])],1),_c('el-tooltip',{attrs:{"content":_vm.$t('message.delete'),"placement":"top"}},[_c('base-button',{attrs:{"type":"link","size":"sm","disabled":_vm.project.state === 'ARCHIVED'},on:{"click":function($event){return _vm.onDelete(row)}}},[_c('i',{staticClass:"fas fa-trash text-black"})])],1)]}}])})],1),_c('rides-form',{attrs:{"ride":_vm.ride,"show":_vm.showForm,"rides":_vm.rides,"parentRideId":_vm.parentRideId,"syncEnabled":_vm.project.pt_sync_enabled == 1 ? true : false},on:{"update:show":function($event){_vm.showForm=$event},"create-new-with-parent":_vm.onCreateNewWithParent,"changed":_vm.onChanged,"load-item":_vm.onLoadItem}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }