<template>
  <div class="card" v-loading="isLoading">
    <div class="card-header">
      <div class="d-flex">
        <h3 class="m-0 pr-4 flex-grow-1">{{$t('message.rides')}}</h3>
        <el-tooltip :content="$t('message.new')" placement="top">
          <base-button type="primary" icon round size="sm" @click="onCreate" :disabled="project.state === 'ARCHIVED'">
            <i class="fas fa-plus p-0"></i>
          </base-button>
        </el-tooltip>
      </div>
    </div>

    <el-table
      class="table-responsive table-compact"
      header-row-class-name="thead-light"
      row-key="id"
      :data="parentRides"
    >
      <el-table-column width="40">
        <template v-slot="{row}" >
          <i class="fas fa-code-branch" v-if="hasChildren(row)"></i>
        </template>
      </el-table-column>
      <el-table-column :label="$t('message.ladeTermin')" prop="load" sortable width="150">
        <template v-slot="{row}">
          {{ row.load | datetime }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('message.einsatzort')" prop="address.name" sortable />
      <el-table-column :label="$t('message.vehicle')" prop="vehicle.type" sortable>
        <template v-slot="{row}">
          <div v-if="row.vehicle">
            {{ row.notice ? row.vehicle.type +' / '+ row.notice : row.vehicle.type }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label width="88">
        <template v-slot="{row}">
          <el-tooltip :content="$t('message.edit')" placement="top">
            <base-button type="link" size="sm" @click="onEdit(row)" :disabled="project.state === 'ARCHIVED'">
              <i class="fas fa-edit text-black"></i>
            </base-button>
          </el-tooltip>
          <el-tooltip :content="$t('message.delete')" placement="top">
            <base-button type="link" size="sm" @click="onDelete(row)" :disabled="project.state === 'ARCHIVED'">
              <i class="fas fa-trash text-black"></i>
            </base-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <rides-form 
      :ride="ride" 
      :show.sync="showForm" 
      :rides="rides" 
      :parentRideId="parentRideId" 
      :syncEnabled="project.pt_sync_enabled == 1 ? true : false" 
      @create-new-with-parent="onCreateNewWithParent" 
      @changed="onChanged" 
      @load-item="onLoadItem" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "@/util/api.js";
import RidesForm from "./RidesForm";

export default {
  name: "rides",
  components: {
    RidesForm
  },
  props: ["project"],
  mounted() {
    this.load();
  },
  computed: {
    ...mapGetters(["vehicles", "projectAddresses"]),
    parentRides () {
      return this.rides.filter(ride => ride.parentRideId === null)
    }
  },
  data() {
    return {
      isLoading: true,
      rides: [],
      ride: {},
      showForm: false,
      parentRideId: null
    };
  },
  methods: {
    load: async function() {
      this.rides = await api("findRides")
        .and("project_id", this.project.id)
        .with("vehicle")
        .with("address")
        .run();
      this.isLoading = false;
    },
    hasChildren(item) {
      return this.rides.filter(ride => ride.parentRideId === item.id).length > 0
    },
    onChanged() {
      this.isLoading = true;
      return this.load();
    },
    onCreate() {
      const { projectAddresses, vehicles } = this;
      this.ride = {
        address_id: projectAddresses.length == 1
          ? projectAddresses[0].address.id
          : null,
        parentRideId: null,
        project_id: this.project.id,
        vehicle_id: vehicles.length ? vehicles[0].id : null
      };
      this.parentRideId = null;

      this.showForm = true;
    },
    async onEdit(ride) {
      await this.load()
        .then(() => {
          this.onLoadItem(ride.id);
          this.showForm = true;
        })
    },
    onLoadItem (id) {
      this.ride = this.rides.find(ride => ride.id === id)
      this.showForm = true;
    },
    onDelete: async function(item) {
      try {
        await this.$confirm(
          this.$t('message.notificationRideDelete'),
          this.$t('message.notificationRideDelete1'),
          {
            confirmButtonText: this.$t("message.yes"),
            cancelButtonText: this.$t("message.no"),
            type: "warning"
          }
        );
      } catch (error) {
        return;
      }

      this.isLoading = true;
      await this.$store.dispatch("deleteRide", { id: item.id });
      this.load();
    },
    async onCreateNewWithParent(parentRideId) {
      const { projectAddresses, ride } = this;
      this.ride = {
        address_id: projectAddresses.length == 1
          ? projectAddresses[0].address.id
          : null,
        parentRideId: parentRideId,
        project_id: this.project.id,
        vehicle_id: ride.vehicle_id
      };
      await this.onChanged()
      this.ride
      this.parentRideId = parentRideId
      this.showForm = true
    }
  }
};
</script>
